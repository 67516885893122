import {
  fetchCalendlyData,
  formatMeetingInfo,
} from '@/components/scheduler/CalendlyEvent/useCalendlyEvent';
import HrRoles, { HrRoleLongNames } from '@/constants/HrRoles';
import { CallKinds } from '@/constants/calendly';
import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';
import {
  CreateHrRequestInput,
  HrRequestSubType,
  useCreateHrRequestMutation,
  useGetCompanyHasRunPayrollQuery,
} from '@/gql/generated';
import useContentful from '@/hooks/useContentful/useContentful';
import useCurrentPlan from '@/hooks/useCurrentPlan/useCurrentPlan';
import useCurrentUser from '@/hooks/useCurrentUser/useCurrentUser';
import useEvents, {
  getScheduledEventFromCalendlyEvent,
} from '@/hooks/useEvents/useEvents';
import bam from '@/lib/bam';
import { calendlyReschedulingUrl } from '@/lib/globals/calendly';
import supportOptionsList from '@/modules/Team/constants/SupportOptions';
import { CompanyStatuses } from '@/modules/payroll/constants/company';
import { useAdvisorsStore } from '@/store/advisors';
import { generateCalendlyURL } from '@/utils/calendly-helpers';
import { isProd } from '@/utils/env-helper';
import { generateCalendlyParameters } from '@/utils/generateCalendlyParameters';
import IPlan from '@@/types/plans';
import useNotifications from '@bambeehr/use-notifications';
import {
  Ref,
  computed,
  reactive,
  ref,
  useStore,
  watch,
} from '@nuxtjs/composition-api';
import { cloneDeep } from 'lodash';
import { GET_HR_REQUEST_LIST } from '@/gql/queries/task_queries.gql';


interface ITeamMember {
  role: typeof HrRoles;
  user: {
    calendlySlug: string;
  };
}

interface ISupportOptions {
  action_options: Array<any>;
  contact_options: Array<any>;
}

export interface ISupportOption {
  key: string;
  type: string;
  subType?: HrRequestSubType;
  topic: string;
  description: string;
  action_options: Array<any>;
  contact_options: Array<any>;
}

export enum TopicKeysAndTitles {
  hr_guidance = 'hr_guidance',
  platform_support = 'platform_support',
  hr_strategy = 'hr_strategy',
  payroll_activation = 'payroll_activation',
  payroll_support = 'payroll_support',
  billing_support = 'billing_support',
  plan_fit = 'plan_fit',
  look_at_FAQ = 'look_at_FAQ',
  report_issue = 'report_issue',
  make_request = 'make_request',
  show_calendar_hrm = 'show_calendar_hrm',
  show_calendar_oc = 'show_calendar_oc',
  show_calendar_bm = 'show_calendar_bm',
  show_calendar_ps = 'show_calendar_ps',
  start_chat_hrc = 'start_chat_hrc',
  start_chat_oc = 'start_chat_oc',
  schedule_a_call = 'schedule_a_call',
  more_options_hr_request = 'more_options_hr_request',
  more_options_report_issue = 'more_options_report_issue',
  payroll_premium = 'payroll_premium',
  hr_support = 'hr_support',
}

export const MyTeamTrackEvents = {
  CLICKED: 'clicked',
  CALL_SCHEDULE: 'call_scheduled',
  CALL_RESCHEDULE_CLICKED: 'call_reschedule_clicked',
  CALL_SCHEDULE_MODAL_CLOSED: 'call_schedule_modal_closed',
} as const;

let initialized;
let addError;
let store;
let companyId;
const TEAM_ROLE_DESCRIPTION_ID = '6zLZKunsPNakGbfU0hWNeB';
export const SCHEDULE_CALL = 'Schedule a Call';
const PAYROLL_SUPPORT_KEY = 'show_calendar_ps';

const EVENT_TYPE_FOR_ROLE = Object.freeze({
  [HrRoles.BM]: 'business-manager-general-call',
  [HrRoles.HRM]: '30min',
  [HrRoles.AC]: 'hr-strategist-overview',
  [HrRoles.OC]: 'customer-support-call',
});

const roleOrder = {
  [HrRoles.HRM]: 0,
  [HrRoles.HRC]: 1,
  [HrRoles.BM]: 2,
  [HrRoles.OC]: 3,
  [HrRoles.PYM]: 4,
};

const HAS_PAYROLL_STATUSES = [
  CompanyStatuses.LIVE,
  CompanyStatuses.SETUP_COMPLETE,
  CompanyStatuses.INITIALIZED,
  CompanyStatuses.PROVISIONED,
];

const isReady = ref(false);
const shouldShowSupportModal = ref(false);
const showHRMSupport = ref(false);
const showConfirmation = ref(false);
const teamMembers: Ref<ITeamMember[]> = ref([]);
const currentCalendlyUrl = ref('');
const currentScheduleCallOwner = ref('');
const currentUser = ref({} as any);
const showCalendlyModal = ref(false);
const hasRunPayrollBefore = ref(false);
const inviteeId = ref('');
const rescheduleUrl = ref('');
const currentOption: Ref<ISupportOption> = ref({} as ISupportOption);
const supportOptions: Ref<ISupportOptions> = ref({
  action_options: [],
  contact_options: [],
});
const hasPayrollManager = ref(false);
const meetingInfo = reactive({
  details: {
    startTime: '',
    day: '',
    month: '',
    dayNumber: '',
    name: '',
    endTime: '',
    uuid: '',
  },
});
const calendarDetails = computed(() =>
  meetingInfo.details?.startTime ? formatMeetingInfo(meetingInfo.details) : null
);

const callDate = computed(
  () =>
    `${
      calendarDetails.value?.startTime ? calendarDetails.value?.startTime : ''
    }, ${calendarDetails.value?.date ? calendarDetails.value?.date : ''}`
);
const supportCurrentPlan: Ref<IPlan> = ref({} as IPlan);
const currentContact = ref({} as any);
const company = ref();

const companyHasPayroll = computed(() => {
  return HAS_PAYROLL_STATUSES.includes(company.value?.status);
});

const myTeamTrack = (event: TopicKeysAndTitles, suffix: string, data: {}) => {
  bam.track(`my_team_${event}_${suffix}`, data);
};

const getCalendlyEventIdFromUri = (uri: string) => {
  return uri.split('/').pop();
};

const getUpdatedTeamMember = (contact, option) =>
  contact === PAYROLL_SUPPORT_KEY
    ? {
        title: 'Business Coordinator',
        role: option.role,
      }
    : option.teamMember;

const getUserDetails = (isActivePayrollMember, option, member) => {
  return isActivePayrollMember
    ? { user: { calendlySlug: option.teamMember?.user?.calendlySlug } }
    : member;
};

const getFinalOption = (contact, option) =>
  contact === PAYROLL_SUPPORT_KEY && hasRunPayrollBefore.value
    ? option
    : {
        ...option,
        teamMember: getUpdatedTeamMember(contact, option),
      };

const mapOptionList = (options, optionList) => {
  return options?.map((action) => optionList?.[action]);
};

const mapContactList = (options, optionList) => {
  return options?.map((contact) => {
    const isActivePayrollMember =
      contact === PAYROLL_SUPPORT_KEY && hasRunPayrollBefore.value;
    const option = optionList?.[contact];
    const finalOption = getFinalOption(contact, option);
    const member = teamMembers.value?.find(
      (m) => m.role === option.teamMember.role
    );

    return {
      ...getUserDetails(isActivePayrollMember, option, member),
      ...finalOption,
    };
  });
};

const mapOptions = (options) => {
  return {
    action_options: mapOptionList(
      options?.action_options,
      supportOptionsList.action_options
    ),
    contact_options: mapContactList(
      options?.contact_options,
      supportOptionsList.contact_options
    ),
  };
};

const isSchedulingWithHRM = computed(
  () =>
    currentOption.value.key === TopicKeysAndTitles.hr_guidance ||
    currentOption.value.key === TopicKeysAndTitles.hr_support
);

const toggleSupportModal = (topic?) => {
  if (!topic) {
    shouldShowSupportModal.value = false;
  }
  currentOption.value = topic;
  myTeamTrack(
    currentOption.value.key as TopicKeysAndTitles,
    MyTeamTrackEvents.CLICKED,
    topic
  );

  supportOptions.value = mapOptions(topic);
  supportOptions.value?.contact_options?.find((option: any) => {
    const calendlyParams = generateCalendlyParameters({
      userId: currentUser.value._id,
      companyId: currentUser.value._company._id,
      userName: currentUser.value.profile.full_name,
      companyName: currentUser.value._company.name,
      email: currentUser.value._auth.email,
      phone: currentUser.value.profile.phone,
      answers: [
        currentUser.value._company.name,
        `1${currentUser.value.profile.phone}`,
      ],
      callKind: topic.calendlyEventName || '',
    });

    if (option.title === SCHEDULE_CALL) {
      const envSuffix = process.env.APP_ENV !== 'production' ? '-dev' : '';
      const calendlyEventTypePath =
        option.calendlyEventType && hasRunPayrollBefore.value
          ? `${option.calendlyEventType}${envSuffix}`
          : '';

      const eventName = topic.calendlyEventName || '/30min';

      const defaultCalendlyUrl = `https://calendly.com/${option?.user?.calendlySlug}${eventName}?${calendlyParams}`;

      if (option.calendlyUrl && option.calendlyEventType) {
        currentCalendlyUrl.value = isProd
          ? `${option.calendlyUrl}?${calendlyParams}`
          : `https://www.calendly.com/devtest-bambee/stage-general-call?${calendlyParams}`;
      } else if (calendlyEventTypePath) {
        currentCalendlyUrl.value = `https://calendly.com${calendlyEventTypePath}/bambee-hr-payroll-support-call?${calendlyParams}`;
      } else {
        currentCalendlyUrl.value = defaultCalendlyUrl;
      }

      currentScheduleCallOwner.value = option.teamMember.role;
    }

    return currentCalendlyUrl.value;
  });
  // if there is only one contact option, and it is to schedule a call AND
  // there are no action options, go ahead and open the calendly modal
  if (
    supportOptions.value?.contact_options?.length === 1 &&
    supportOptions.value?.contact_options[0].title === SCHEDULE_CALL &&
    supportOptions.value?.action_options?.length === 0
  ) {
    currentContact.value = supportOptions.value?.contact_options[0];
    showCalendlyModal.value = true;

    return;
  }
  shouldShowSupportModal.value = !shouldShowSupportModal.value;
};

const hrRequest = () => {
  const {
    mutate: createHrRequest,
    onDone: onCreateHrRequestDone,
    onError: onCreateHrRequestError,
    loading: createHrRequestLoading,
  } = useApolloMutation(useCreateHrRequestMutation);

  const createHrRequestMutation = (input: CreateHrRequestInput) => {
    createHrRequest(
      { input },
      {
        refetchQueries: [
          {
            query: GET_HR_REQUEST_LIST,
          },
        ],
      }
    );
  };

  onCreateHrRequestDone((status) => {
    Intercom(
      'showConversation',
      Number(status?.createHrRequest?.intercomConversationId)
    );
  });

  onCreateHrRequestError(() => {
    addError('Failed to start chat, please try again.');
  });

  return {
    createHrRequestMutation,
    createHrRequestLoading,
    onCreateHrRequestError,
  };
};

const handleCallScheduled = async (meetingDetails) => {
  myTeamTrack(
    currentOption.value.key as TopicKeysAndTitles,
    MyTeamTrackEvents.CALL_SCHEDULE,
    currentOption.value
  );
  showConfirmation.value = true;

  const meetingId = getCalendlyEventIdFromUri(
    meetingDetails?.payload?.event?.uri
  );

  inviteeId.value =
    getCalendlyEventIdFromUri(meetingDetails.payload.invitee.uri) || '';

  meetingInfo.details = await fetchCalendlyData(
    store,
    getCalendlyEventIdFromUri(meetingDetails.payload.event.uri)
  );

  const utmContent = [companyId.value, currentUser.value?._id, meetingId];

  rescheduleUrl.value = calendlyReschedulingUrl(inviteeId.value, utmContent);

  // Stub events populate the "upcoming events" module of the dashboard
  const { addStubEvent } = useEvents();
  const stubDetails = await getScheduledEventFromCalendlyEvent(
    meetingDetails,
    currentCalendlyUrl.value
  );
  addStubEvent(stubDetails);
};

const handleScheduleHRMSupport = () => {
  showCalendlyModal.value = false;
  const calendlyParams = generateCalendlyParameters({
    userId: currentUser.value._id,
    companyId: currentUser.value._company._id,
    userName: currentUser.value.profile.full_name,
    companyName: currentUser.value._company.name,
    email: currentUser.value._auth.email,
    phone: currentUser.value.profile.phone,
    answers: [
      currentUser.value._company.name,
      `1${currentUser.value.profile.phone}`,
    ],
    callKind: CallKinds.HRM_SUPPORT,
  });

  const isProd = process.env.APP_ENV === 'production';

  const newCalendlyUrl = `https://calendly.com/bambee-hr-team${
    isProd ? '' : '-stage'
  }/hrm-support?${calendlyParams}`;

  currentCalendlyUrl.value = newCalendlyUrl;
  showHRMSupport.value = true;
};

const setupHook = async () => {
  const tmpStore = useStore();
  store = tmpStore;
  const { isTierZero, currentPlan } = useCurrentPlan();
  const { currentUser: tmpCurrentUser } = useCurrentUser();
  const { addError: tmpAddError } = useNotifications();
  addError = tmpAddError;
  supportCurrentPlan.value = currentPlan.value as IPlan;

  const { companyId: tmpCompanyId } = store.getters;
  companyId = tmpCompanyId;
  const { onResult: onCompanyResult } = useApolloQuery(
    useGetCompanyHasRunPayrollQuery,
    {
      id: companyId,
    }
  );

  onCompanyResult((data) => {
    const { getCompany } = data;
    company.value = getCompany;
    hasRunPayrollBefore.value = getCompany?.hasRunPayroll ?? false;
  });

  currentUser.value = tmpCurrentUser.value;
  const { fetchContent } = useContentful();
  const content = ref();

  (async () => {
    content.value = await fetchContent(TEAM_ROLE_DESCRIPTION_ID);
  })();

  const team = computed(() => {
    const companyAdvisorText = 'New Company Advisor';

    const advisorStore = useAdvisorsStore();
    const advisorTeam = computed(() => advisorStore.hrAdvisors);
    const formattedTeam = advisorTeam.value.map((teamMember) => {
      const tm = cloneDeep(teamMember);
      const eventType = EVENT_TYPE_FOR_ROLE[tm.role] || '';
      const ownerSlug = tm.user.calendlySlug;
      let calendlyUrl = generateCalendlyURL({
        ownerSlug,
        eventType,
        guest: currentUser.value,
      });

      let description =
        content.value?.list?.find((i) => i.fields.id === tm.role.toUpperCase())
          ?.fields.text || '';

      const supportTopics =
        content.value?.list?.find((i) => i.fields.id === tm.role.toUpperCase())
          ?.fields.list || [];

      if (tm.role === HrRoles.HRM && isTierZero.value) {
        description = description.replace(
          HrRoleLongNames[HrRoles.HRM],
          companyAdvisorText
        );
      }

      // Remove ability to schedule with BMs
      // https://bambee.atlassian.net/browse/BB-1746?focusedCommentId=70054
      if (tm.role === HrRoles.BM) {
        calendlyUrl = '';
      }

      return {
        ...tm,
        // HRM should always be the primary contact
        isPrimary: tm.role === HrRoles.HRM,
        calendlyUrl,
        description,
        supportTopics,
      };
    });

    return formattedTeam;
  });

  const validTeamMembers = computed(() => {
    const validRoles = [
      HrRoles.BM,
      HrRoles.HRM,
      HrRoles.HRC,
      HrRoles.OC,
      HrRoles.PYM,
    ];

    const members = team.value.filter((teamMember) =>
      // @ts-ignore
      validRoles.includes(teamMember.role)
    );

    const orderedTeamMembers = members.sort((a, b) => {
      return roleOrder[a.role] - roleOrder[b.role];
    });

    return orderedTeamMembers;
  });

  watch(
    validTeamMembers,
    () => {
      // @ts-ignore
      teamMembers.value = validTeamMembers.value;

      const payrollManager = teamMembers.value.find(
        // @ts-ignore
        (member) => member.role === HrRoles.PYM
      );

      hasPayrollManager.value = !!payrollManager;
    },
    {
      immediate: true,
    }
  );
};

export const useSupportOptions = () => {
  if (!initialized) {
    initialized = true;
    setupHook();
  }

  return {
    // State
    shouldShowSupportModal,
    supportOptions,
    teamMembers,
    isReady,
    currentCalendlyUrl,
    currentScheduleCallOwner,
    showCalendlyModal,
    companyHasPayroll,
    hasRunPayrollBefore,
    currentOption,
    currentContact,
    showConfirmation,
    isSchedulingWithHRM,
    showHRMSupport,
    meetingInfo,
    inviteeId,
    rescheduleUrl,
    hasPayrollManager,
    // Methods
    toggleSupportModal,
    hrRequest,
    myTeamTrack,
    handleScheduleHRMSupport,
    handleCallScheduled,
  };
};

export default useSupportOptions;
